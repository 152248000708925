var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:" mb-3",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-btn',{staticClass:"btn",attrs:{"color":"primary","large":""},on:{"click":function($event){return _vm.openAddDialog()}}},[_c('v-icon',{staticClass:"ml-2"},[_vm._v("add_circle_outline")]),_c('h3',[_vm._v("إضافة "+_vm._s(this.$route.meta.single))])],1)],1),_c('v-spacer')],1),_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"items-per-page":_vm.items.length,"items":_vm.items,"headers":_vm.headers,"loading":_vm.loading,"hide-default-footer":"","no-data-text":"لا توجد بيانات","loading-text":"جاري تحميل البيانات","id":_vm.$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'},scopedSlots:_vm._u([{key:"item.imageUrl",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"tile":"","size":"100"}},[_c('v-img',{attrs:{"src":_vm.$url+'/'+item.imageUrl}})],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(item.id)}}},on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" fi fi-rr-trash ")])],1)]}}],null,true)},[_c('span',[_vm._v("حذف")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.openEditDialog(item)}}},on),[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(" fi fi-rr-edit ")])],1)]}}],null,true)},[_c('span',[_vm._v("تعديل")])])]}}])})],1),_c('Pagination'),_c('Dialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }